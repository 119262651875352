<template>
    <div class="vstack gap-4">
        <MenuTemplates :user="user" :setMenu="setMenu" @showTemplates="showTemplates = $event" @msg="emitMsg($event)" />
        <div class="vstack gap-4" v-if="!showTemplates || menu">
            <div class="bg-white p-4 rounded shadow-sm">
                <b-form class="vstack gap-2" @submit.prevent="setForm">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="vstack">
                            <b-form-group label="Tipo de ChatBot:" label-class="text-dark pb-0" class="text-secondary">
                                <b-form-radio-group class="d-flex" v-model="form.chatbotType" v-if="user?.channelConfig?.modules?.linearChatbot">
                                    <b-form-radio class="d-inline-flex me-3" value="1">
                                        <div class="ms-2">Chatbot Fluxo/URA</div>
                                    </b-form-radio>
                                    <b-form-radio class="d-inline-flex me-3" value="4">
                                        <div class="ms-2">Chatbot Linear</div>
                                    </b-form-radio>
                                </b-form-radio-group>
                                <b-form-radio-group class="d-flex gap-3" v-model="form.chatbotType" v-else>
                                    <b-form-radio class="d-inline-flex" value="1">
                                        <div class="ms-2">Menu Simples</div>
                                    </b-form-radio>
                                    <b-form-radio class="d-inline-flex" value="2" v-if="user.channel?.apiType == 'cloud' || user.channel?.apiType == 'gupshup'">
                                        <div class="ms-2">Lista Interativa</div>
                                    </b-form-radio>
                                    <b-form-radio class="d-inline-flex" value="3">
                                        <div class="ms-2">Botões Interativos</div>
                                    </b-form-radio>
                                    <b-form-radio class="d-inline-flex" value="ai" v-if="user.channelConfig?.modules?.ai && user.channelConfig.aiEnabled">
                                        <div class="ms-2">IA Gotalk</div>
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <div v-if="chatbotType === 'ai'">
                                <b-form-checkbox class="d-flex gap-2 text-secondary" v-model="form.aiPresentation">
                                    Ativar mensagem de apresentação
                                </b-form-checkbox>
                            </div>
                        </div>
                        <div>
                            <b-button type="submit" class="managemenubtngreen">Selecionar</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
            <b-form class="vstack gap-4" @submit.prevent="onSubmitAiConfig" v-if="chatbotType === 'ai' && !form.aiPresentation">
                <div class="vstack bg-white rounded shadow-sm">
                        <div class="hstack gap-2 p-4 border-bottom">
                        <b-iconstack font-scale="3.5">
                            <b-icon stacked icon="circle-fill" variant="green"/>
                            <b-icon stacked icon="file-earmark-spreadsheet" scale=".5" variant="white"/>
                        </b-iconstack>
                        <h5>Dados de Treinamento</h5>
                    </div>
                    <div class="aiTrainingWrapper">
                        <b-form-file id="trainingUpload" style="display:none" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" multiple @input="onInputAiTraining" />
                        <label for="trainingUpload" class="w-100" role="button">
                            <div class="bg-purple text-light p-5" :class="{ 'rounded-bottom': !aiTraining.length }">
                                <div class="d-flex flex-column align-items-center gap-2">
                                    <b-icon class="fs-1" icon="file-earmark-spreadsheet"/>
                                    <div class="fs-6">Importar Planilha de Treinamento</div>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="d-flex justify-content-center gap-3 p-4 overflow-x-auto" v-if="aiTraining.length">
                        <div class="d-flex flex-column align-items-center position-relative" v-for="file in aiTraining" :key="file.name">
                            <b-icon class="fs-1 text-success" icon="file-earmark-spreadsheet-fill"/>
                            {{ file.name }}
                            <b-icon class="fs-6 text-danger position-absolute top-0 end-0" icon="x-circle-fill" role="button" @click="removeAiTraining(file)" />
                        </div>
                    </div>
                </div>
                <div class="vstack bg-white rounded shadow-sm">
                    <div class="hstack gap-2 p-4 border-bottom">
                        <b-iconstack font-scale="3.5">
                            <b-icon stacked icon="circle-fill" variant="green"/>
                            <b-icon stacked icon="terminal" scale=".5" variant="white"/>
                        </b-iconstack>
                        <h5>Prompt</h5>
                    </div>
                    <div class="p-4">
                        <b-form-group label="Digite seu Prompt:" label-class="text-dark" class="text-secondary">
                            <b-form-textarea rows="3" required v-model="aiConfig.additionalPrompt" />
                        </b-form-group>
                    </div>
                </div>
                <div class="bg-white p-4 rounded shadow-sm">
                    <b-button type="submit" class="text-white w-100" variant="green">Salvar</b-button>
                </div>
            </b-form>
            <span v-else-if="chatbotType == 1 || (chatbotType === 'ai' && form.aiPresentation)">
                <ManageMenu @msg="emitMsg" :aiPresentation="this.user.channelConfig?.modules?.ai && this.user.channelConfig.aiEnabled && chatbotType === 'ai' && form.aiPresentation" :user="user" :menuId="menu?._id" />
            </span>
            <span v-else-if="chatbotType == 2">
                <ManageList @msg="emitMsg" :user="user" :menuId="menu?._id" />
            </span>
            <span v-else-if="chatbotType == 3">
                <ManageButton @msg="emitMsg" :user="user" :menuId="menu?._id" />
            </span>
            <span v-else-if="chatbotType == 4">
                <ManageMenuLinear @msg="emitMsg" :user="user" :menuId="menu?._id" />
            </span>
        </div>
    </div>
</template>

<script>
import ManageMenu from './managemenu.vue'
import ManageList from './manageList.vue'
import ManageButton from './manageButton.vue'
import ManageMenuLinear from './manageMenuLinear.vue'
import api from '../services/apiService.js'
import MenuTemplates from './menuTemplates.vue'
import { getToken } from '../services/userService.js'

export default {
    components:{
        ManageMenu, ManageList, ManageButton, ManageMenuLinear,
        MenuTemplates,
    },
    props: ['user'],
    mounted() {
        this.getMenus()
        this.getAIConfig()
    },
    methods: {
        setForm() {
            this.chatbotType = this.form.chatbotType
            this.aiPresentation = this.form.aiPresentation
        },
        async getMenus() {
            const resp = await api.getMenus(this.user.channelId || this.user.roleId)
            if(resp.statusCode == 200 && resp.menus.length) {
                const mainMenuIndex = resp.menus.findIndex(el => !el.topMenuId)
                const menu = resp.menus[mainMenuIndex]
                if (resp.menus?.filter((el) => !el.topMenuId).length === 1) {
                    this.menu = menu
                }
                this.handleMenu(menu)
            }
        },
        handleMenu(menu) {
            if(!menu.topMenuId)
                menu.topMenuId = undefined
            if(!menu.refObjectMenus)
                menu.refObjectMenus = undefined
            
            console.log('menu',menu)
            if(menu.interactive?.type) {
                switch(menu.interactive.type) {
                    case 'list':
                    this.chatbotType = 2
                    this.form.chatbotType = 2
                    break
                    case 'button':
                    this.chatbotType = 3
                    this.form.chatbotType = 3
                    break
                }
            } else {
                if (menu.isLinear) {
                    this.chatbotType = this.form.chatbotType = 4
                } else if (menu.ai?.enabled) {
                    this.chatbotType = this.form.chatbotType = 'ai'
                    this.aiPresentation = this.form.aiPresentation = menu.ai?.presentation
                } else {
                    this.chatbotType = this.form.chatbotType = 1
                }
            }
        },
        async getAIConfig() {
            const resp = await api.getAIConfigByChannelId(this.user.channelId || this.user.roleId)
            
            if (resp?.statusCode == 200) {
                if (!resp.data.additionalPrompt) resp.data.additionalPrompt = ''
                this.aiConfig = resp.data
            }
        },
        emitMsg(msg) {
            this.$emit('msg',msg)
        },
        setMenu(menu) {
            console.log({menu})
            this.menu = null
            setTimeout(() => {
                this.menu = menu
                this.handleMenu(menu)
            }, 100)
        },
        onInputAiTraining(files) {
            for (const file of files) {
                if (!this.aiTraining.includes(file)) {
                    this.aiTraining.push(file)
                }
            }
        },
        async onSubmitAiConfig() {
            const channelId = this.user.channelId || this.user.roleId

            if (this.aiTraining?.length) {
                for (const file of this.aiTraining) {
                    const formData = new FormData()
                    file.filename = file.name
                    formData.append('file', file)
                    const token = getToken()
                    const typefile = file['type'].split('/')[0]
                    const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber': channelId }
                    const uploadResp = await api.upload(formData,headers)
                    if(uploadResp.statusCode !== 200) {
                        this.$emit('msg', {
                            text: `Ocorreu um erro ao ler arquivo ${file.name}! Tente novamente mais tarde!`,
                            success: false,
                            countdown: 8
                        })

                        continue
                    }

                    const url = uploadResp.file.uploadDir

                    if (!this.aiConfig.trainingDataUrls) {
                        this.aiConfig.trainingDataUrls = []
                    }

                    this.aiConfig.trainingDataUrls.push(url)
                }
            }

            await api.updateAIConfigByChannelId(channelId, this.aiConfig)

            if (!this.menu) {
                const respCreate = await api.createMenu({ 
                    name: `Menu Principal`,
                    // name: `Menu Principal ${this.user.channelId || this.user.roleId}`,
                    topMenuId: undefined,
                    refObjectMenus: undefined,
                    channelId: channelId,
                    ai: {
                        enabled: true,
                        presentation: this.form.aiPresentation
                    }
                })

                if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu) {
                    return this.$emit('msg', {
                        text: "Ocorreu um erro ao criar menu!",
                        success: false
                    })
                }
            } else {
                this.menu.ai = {
                    enabled: true,
                    presentation: this.form.aiPresentation
                }

                await api.updateMenu({ menus: [ this.menu ] })
            }

            this.$emit('msg', {
                text: "Configurações salvas com sucesso!",
                success: true
            })
        },
        removeAiTraining(file) {
            this.aiTraining = this.aiTraining.filter(el => el !== file)
        }
    },
    data() {
        return {
            form: {
                chatbotType: '',
                aiPresentation: ''
            },
            chatbotType: "",
            showTemplates: false,
            menu: null,
            aiPresentation: false,
            aiConfig: {
                systemPrompt: "",
                additionalPrompt: "",
                trainingDataUrls: []
            },
            aiTraining: []
        }
    }
}
</script>

<style scoped>
    .managemenubtngreen {
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        padding: 5px 20px;
    }
</style>